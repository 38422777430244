import {
  resourceGoals,
  resourceIndustries,
  resourceTags,
  spotonProducts,
} from "../../constants";

export default {
  pageTitle: "SpotOn Case Study | Base Camp Pizza Co.",
  pageDescription:
    "Find out how a popular pizza place in a Lake Tahoe ski resort keeps a 125-employee operation running smoothly using SpotOn’s suite of restaurant products.",
  title: "Hot pizza after cold ski days",
  subTitle: `How Base Camp Pizza Co. keeps crowds of skiers happy with the help of SpotOn.`,
  businessName: "Base Camp Pizza Co.",
  industry: "Restaurants & hospitality, casual dining",
  location: "Lake Tahoe, California",
  products: [
    spotonProducts.RESTAURANT,
    spotonProducts.SERVE,
    spotonProducts.ORDER,
    spotonProducts.RESERVE,
  ],
  description:
    "Located in the heart of the Heavenly Mountain Resort of Lake Tahoe, Base Camp Pizza Co. serves fresh pizza to scores of hungry vacationers every day. Through a partnership with SpotOn, they’re able to effortlessly manage their entire restaurant under one platform.",
  quoteData: {
    imgName: "base-camp-pizza.png",
    quote: `“It’s so easy to make changes and adjust variables that affect restaurant operations. If a feature isn’t there, it’s a simple phone call to SpotOn. They have the best response time, and when you need somebody, they’re there.”`,
    personName: "Ray Villaman",
    personTitle: "Owner, Base Camp Pizza Co.",
  },
  goal: `Owner Ray Villaman needed an all-in-one restaurant management solution to streamline his busy pizza company. He wanted a system that could handle online reservations, the waitlist, restaurant reporting, and all types of payment processing.`,
  solution: `SpotOn Restaurant with SpotOn Serve handhelds simplified in-house ordering and payments, while SpotOn Order allowed customers to order and pay online. Meanwhile, SpotOn Reserve provided Base Camp with a digital waitlist and online reservations.`,
  results: {
    title: `The results`,
    stats: [
      { title: `+27.4%`, description: `online ordering transactions` },
      {
        title: `+41.6%`,
        description: `online ordering processing`,
      },
      { title: `$5,000`, description: `increase in average daily sales` },
    ],
  },
};

export const relatedSuccessStories = [
  {
    title: "A bigger slice of the pie",
    imageName: "bella-pizza.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-05-05",
    href: "/case-studies/bella-pizza",
    goals: [
      resourceGoals.CONNECT_AND_SELL_ONLINE,
      resourceGoals.GET_SMARTER_DATA,
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.MANAGE_AND_RETAIN_EMPLOYEES,
    ],
  },
  {
    title: "Selling more slices of pizza",
    imageName: "tonys-pizza.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-02-24",
    href: "/case-studies/tonys-pizza",
    goals: [
      resourceGoals.CONNECT_AND_SELL_ONLINE,
      resourceGoals.REACH_NEW_CUSTOMERS,
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
    ],
  },
  {
    title: "Beer, beef, and baseball",
    imageName: "von-elrods-beer-hall-and-kitchen.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-02-21",
    href: "/case-studies/von-elrods-beer-hall-and-kitchen",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
      resourceGoals.GET_SMARTER_DATA,
    ],
  },
];
